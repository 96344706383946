import img1 from "../assets/images/carousel1.png";
import img2 from "../assets/images/carousel2.png";
import img3 from "../assets/images/carousel3.png";
import img4 from "../assets/images/carousel4.png";
import img5 from "../assets/images/carousel5.png";
import img6 from "../assets/images/carousel6.png";
import img7 from "../assets/images/carousel7.png";
import img8 from "../assets/images/carousel8.png";
import { SliderDisplayDataType, AlbumlistDataType } from "../types";
import m1 from "../assets/images/m1.svg";
import m2 from "../assets/images/m2.svg";
import m3 from "../assets/images/m3.svg";
import m4 from "../assets/images/m4.svg";
import m5 from "../assets/images/m5.svg";
import m6 from "../assets/images/m6.svg";
import m7 from "../assets/images/m7.svg";

export const sliderDisplayData: SliderDisplayDataType[] = [
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img1,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img2,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img3,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img4,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img5,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img6,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img7,
  },
  {
    title: "The secret place (live)",
    type: "Album",
    artist: "1spirit & Theophilus Sunday",
    image: img8,
  },
];

export const albumListData: AlbumlistDataType[] = [
  {
    title: "Yeshua Hamashiach(Overture)",
    artist: "Nathaniel Bassey",
    duration: "7:56",
  },
  {
    title: "Ruach Elohim",
    artist: "Nathaniel Bassey",
    duration: "10:01",
  },
  {
    title: "Lift up your head (Psalm 24)",
    artist: "Nathaniel Bassey",
    duration: "5:39",
  },
  {
    title: "See What the Lord Has done",
    artist: "Nathaniel Bassey",
    duration: "7:34",
  },
  {
    title: "Yeshua Hamashiach(Overture)",
    artist: "Nathaniel Bassey",
    duration: "7:56",
  },
  {
    title: "Ruach Elohim",
    artist: "Nathaniel Bassey",
    duration: "10:01",
  },
  {
    title: "Lift up your head (Psalm 24)",
    artist: "Nathaniel Bassey",
    duration: "5:39",
  },
  {
    title: "See What the Lord Has done",
    artist: "Nathaniel Bassey",
    duration: "7:34",
  },
];

export const albumMenuModalData = [
  {
    title: "Add to library",
    icon: m1,
  },
  {
    title: "Save to playlist",
    icon: m2,
  },
  {
    title: "Go to artist",
    icon: m3,
  },
  {
    title: "Shuffle play",
    icon: m4,
  },
  {
    title: "Play next",
    icon: m5,
  },
  {
    title: "Report",
    icon: m6,
  },
  {
    title: "Share",
    icon: m7,
  },
];
