import React from 'react'

function Search() {
  return (
    <div className='text-white'>
        hello world this is the search page
    </div>
  )
}

export default Search